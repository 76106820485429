import React, { useCallback, useState } from 'react';

import { StrapiApiClient } from '../../lib/integrations/strapi/StrapiApiClient';
import FormButton from './FormButton';
import FormInput from './FormInput';

type GetInTouchEmailOnlyFormProps = {};

const GetInTouchEmailOnlyForm: React.FC<GetInTouchEmailOnlyFormProps> = React.memo((props) => {
  const [showSuccessForm, setShowSuccessForm] = useState(false);
  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const email = event.target['email-input'].value;
      const postData = {
        ['by_checking_this_box,_i_agree_to_receive_company_news_and_updates._learn_more_in_the_privacy_policy.']: false,
        full_name: String(email),
        company: 'N/A',
        email,
        description: `Request from page footer`,
        phone_number: 'N/A',
        job_title: 'N/A',
      };
      // Submit the form
      StrapiApiClient.postContactUsRequest(postData).then((response) => {
        if (response.ok) {
          setShowSuccessForm(true);
        }
      });
    },
    [setShowSuccessForm],
  );

  if (showSuccessForm) {
    return (
      <div className="text-center flex flex-col justify-items-center overflow-x-scroll">
        <p className="font-bold text-xl mb-2">Thank you for getting in touch! </p>
        <p className="text-base">A member of our team will get back in touch with you within 1 business day. </p>
      </div>
    );
  }

  return (
    <div className="text-center flex flex-col justify-items-center">
      <form onSubmit={handleFormSubmit} className="flex flex-col gap-4 w-full max-w-4xl self-center text-left">
        <div className="col-span-12">
          <FormInput required={true} className="max-w-[450px]" type="email" id="email-input" label="Email" placeholder="Type here..." />
        </div>
        <div className="col-span-12">
          <FormButton color="blue">Contact Us</FormButton>
        </div>
      </form>
    </div>
  );
});
GetInTouchEmailOnlyForm.displayName = 'GetInTouchEmailOnlyForm';
export default GetInTouchEmailOnlyForm;
