import React, { useRef } from 'react';

import { QuestionMarkIcon } from '../icons/SvgIcons';

type TooltipProps = {
  content: string;
  width?: number;
};

const Tooltip: React.FunctionComponent<TooltipProps> = (props): JSX.Element => {
  const { content, width } = props;
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left } = container.current.getBoundingClientRect();

        const tooltipHeight = tooltipRef.current.clientHeight;
        const tooltipWidth = tooltipRef.current.clientWidth;

        tooltipRef.current.style.left = clientX - left - tooltipWidth / 2 + 'px';
        tooltipRef.current.style.top = -20 - tooltipHeight + 'px';
      }}
      className="group relative inline-block cursor-pointer"
    >
      <QuestionMarkIcon />

      <span
        ref={tooltipRef}
        style={{ width: `${width | 300}px` }}
        className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-center text-white text-sm p-2 rounded absolute top-full mt-2 break-words"
      >
        {content}
      </span>
    </div>
  );
};
Tooltip.displayName = 'Tooltip';
export default Tooltip;
